<template>
    <div class="content sto-request-page">
        <div class="content__title text_40_b">Заказать услугу</div>
        <div class="form">
            <div class="form__header text_24_b">Добавьте услуги</div>
            <div class="form__content">
                <form class="services-form" @submit.prevent="addRow(currentService)">
                    <div class="flex">
                        <input class="form-group__input" type="text" required v-model="currentService" />
                        <button class="button" type="submit">добавить</button>
                    </div>
                </form>
                <div class="example">
                    Например <a href="#" @click.prevent="addRow('проточка диска')">проточка диска</a> или
                    <a href="#" @click.prevent="addRow('схождение')">схождение</a>
                </div>
            </div>
            <div class="form__header text_24_b" v-if="services.length">Выбранные услуги</div>
            <div class="form__content" v-if="services.length">
                <div class="services-list">
                    <ul>
                        <li v-for="(service, index) in services" :key="index">
                            {{ service }} <span @click="removeRow(index)"></span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="form__header text_24_b">Выберите автомобиль (необязательно)</div>
            <div class="form__content">
                <dropdown
                    :key="`form.year.value_${form.year.value}`"
                    class="my-dropdown-toggle"
                    :options="form.year.options"
                    :selected="form.year.value"
                    :placeholder="'Выберите год'"
                    :closeOnOutsideClick="true"
                    @updateOption="getMarksByYear($event)"
                ></dropdown>
                <dropdown
                    :key="`form.mark.value_${form.mark.value}`"
                    class="my-dropdown-toggle"
                    :options="form.mark.options"
                    :selected="form.mark.value"
                    :placeholder="'Выберите марку'"
                    :closeOnOutsideClick="true"
                    @updateOption="getModelsByMarkId($event)"
                ></dropdown>
                <dropdown
                    :key="`form.model.value_${form.model.value}`"
                    class="my-dropdown-toggle"
                    :options="form.model.options"
                    :selected="form.model.value"
                    :placeholder="'Выберите модель'"
                    :closeOnOutsideClick="true"
                    @updateOption="getModsByModelId($event)"
                ></dropdown>
                <dropdown
                    :key="`form.mod.value_${form.mod.value}`"
                    class="my-dropdown-toggle"
                    :options="form.mod.options"
                    :selected="form.mod.value"
                    :placeholder="'Выберите модификацию'"
                    :closeOnOutsideClick="true"
                    @updateOption="form.mod.value = $event"
                ></dropdown>
            </div>
            <transition name="fade" mode="out-in">
                <div v-if="!sended" key="1" class="form__content">
                    <div class="form-group">
                        <div class="form-field form-field--wide">
                            <div class="form-field__label">
                                Ваше имя: <span class="error">{{ errorMessages.name }}</span>
                            </div>
                            <div class="form-field__component">
                                <input class="form-group__input" type="text" required v-model="userName" />
                            </div>
                        </div>
                        <div class="form-field form-field--wide">
                            <div class="form-field__label">Ваш телефон:</div>
                            <div class="form-field__component">
                                <vue-phone-number-input
                                    no-flags
                                    :preferred-countries="['RU']"
                                    :translations="phoneTranslations"
                                    v-model="phone"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="form-field form-field--wide">
                            <div class="form-field__label">Как с вами связаться:</div>
                            <div class="form-field__component">
                                <dropdown
                                    class="my-dropdown-toggle"
                                    :options="how_to_contact_list"
                                    :selected="how_to_contact"
                                    :placeholder="'Выберите'"
                                    :closeOnOutsideClick="true"
                                    @updateOption="how_to_contact = $event"
                                >
                                </dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="form-group form-group--lg-offset">
                        <div class="form-field form-field--centered ">
                            <div class="button button--blue" @click="submit()">
                                Отправить
                                <div class="loader" v-if="requestStatus === 'loading'"></div>
                            </div>
                        </div>
                    </div>
                    <div class="text_24_b tac submit-error" v-if="requestStatus === 'error'">
                        Произошла ошибка отправки формы, <br />позвоните по номеру телефона
                        <a href="tel:+78482713013">8 (8482) 713 - 013</a>
                    </div>
                </div>
                <div class="form__content" key="2" v-else>
                    <div class="text_24_b tac">
                        Заявка отправлена, менеджер свяжется с вами в ближайшее время
                    </div>
                </div>
            </transition>
            <div class="form__footer text_12">
                Отправляя данную форму, я даю согласие на
                <a href="https://arlan-auto.ru/public-oferta" target="_blank">обработку персональных данных</a>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import dropdown from "vue-dropdowns";
export default {
    name: "Index",
    components: {
        dropdown,
    },
    data() {
        const year = new Date().getFullYear();
        const yearsOptions = [];

        for (let index = 1991; index <= year; index++) {
            yearsOptions.push({ name: index.toString(), value: index.toString() });
        }
        return {
            form: {
                year: { options: yearsOptions, value: "0" },
                mark: { options: [{ name: "Марка", value: "0" }], value: "0" },
                model: { options: [{ name: "Модель", value: "0" }], value: "0" },
                mod: { options: [{ name: "Модификация", value: "0" }], value: "0" },
            },
            requestStatus: "",
            sended: false,
            errorMessages: {
                name: "",
            },
            how_to_contact_list: [
                {
                    name: "Позвоните мне",
                },
                {
                    name: "Напишите мне в Telegram",
                },
                {
                    name: "Напишите мне в WhatsApp",
                },
                {
                    name: "Напишите мне в Viber",
                },
            ],
            how_to_contact: {
                name: "Позвоните мне",
            },
            currentService: "",
            services: [],
            userName: "",
            phone: "",
            smartToken: "",
            phoneTranslations: {
                countrySelectorLabel: "Код",
                countrySelectorError: "Ошибка",
                phoneNumberLabel: "Номер телефона",
                example: "Например:",
            },
        };
    },
    created() {
        if (this.$route.query.service) {
            this.services.push(this.$route.query.service);
        }
    },
    methods: {
        async getMarksByYear(year) {
            this.form.year.value = year;
            this.form.mark = Object.assign({}, { options: [{ name: "Марка", value: "0" }], value: "0" });
            this.form.model = Object.assign({}, { options: [{ name: "Модель", value: "0" }], value: "0" });
            this.form.mod = Object.assign({}, { options: [{ name: "Модификация", value: "0" }], value: "0" });
            const { data } = await axios.get("https://api.arlan-auto.ru/api/v1/catalog/to/manufacturers/" + year.value);
            this.form.mark.options.push(...this.convertDataToOptions(data.data));
        },
        async getModelsByMarkId(id) {
            this.form.mark.value = id;
            this.form.model = Object.assign({}, { options: [{ name: "Модель", value: "0" }], value: "0" });
            this.form.mod = Object.assign({}, { options: [{ name: "Модификация", value: "0" }], value: "0" });
            const { data } = await axios.get(
                `https://api.arlan-auto.ru/api/v1/catalog/to/models/${id.value}/${this.form.year.value.value}`
            );
            this.form.model.options.push(...this.convertDataToOptions(data.data));
        },
        async getModsByModelId(id) {
            this.form.model.value = id;
            this.form.mod = Object.assign({}, { options: [{ name: "Модификация", value: "0" }], value: "0" });
            const { data } = await axios.get(
                `https://api.arlan-auto.ru/api/v1/catalog/to/modifications/${id.value}/${this.form.year.value.value}`
            );
            this.form.mod.options.push(...this.convertDataToOptions(data.data));
        },
        convertDataToOptions(data) {
            const res = data.map((el) => {
                return {
                    name: el.name,
                    value: el.id,
                };
            });
            return res;
        },
        addRow(service) {
            if (!this.services.includes(service)) {
                this.services.push(service);
                this.currentService = "";
            }
        },
        removeRow(index) {
            this.services.splice(index, 1);
        },
        showError(field) {
            let message = "заполните это поле";
            if (field == "name") {
                message = "заполните имя";
            }
            this.errorMessages[field] = message;
        },
        async submit() {
            if (!this.userName) {
                this.showError("name");
                return false;
            }

            this.requestStatus = "loading";

            window.smartCaptcha.execute();
            setTimeout(async () => {
                this.smartToken = await window.smartCaptcha.getResponse();
                if (!this.smartToken) {
                    console.error(`не получен токен капчи =>`);
                    this.requestStatus = "error";
                    return;
                }

                axios.defaults.withCredentials = true;

                axios
                    .get("https://api.arlan-auto.ru/api/v1/csrf_token")
                    .then(() => {
                        axios
                            .post("https://api.arlan-auto.ru/api/v1/amo/create/lead/sto/form-request", {
                                name: this.userName,
                                phone: "+7 " + this.phone,
                                services: this.services,
                                car_year: this.form.year.value.name,
                                car_mark: this.form.mark.value.name,
                                car_model: this.form.model.value.name,
                                car_modification: this.form.mod.value.name,
                                how_to_contact: this.how_to_contact.name,
                                "smart-token": this.smartToken,
                            })
                            .then(() => {
                                this.sended = true;
                                this.requestStatus = "";
                            })
                            .catch((e) => {
                                console.log(`e =>`, e);
                                this.requestStatus = "error";
                            });
                    })
                    .catch((e) => {
                        console.log(`e =>`, e);
                        this.requestStatus = "error";
                    });
            }, 300);
        },
    },
};
</script>
<style lang="less">
@import url("../../assets/less/vars.less");
.sto-request-page {
    .form__header {
        padding-bottom: 20px;
    }
    .form__content + .form__header {
        padding-top: 0;
    }
    .services-list {
        ul {
            margin: 0;
            padding: 0;
        }
        li {
            display: flex;
            align-items: baseline;
            &::before {
                content: "-";
                margin-right: 5px;
            }
            span {
                &::before {
                    display: inline-block;
                    vertical-align: middle;
                    align-self: center;
                    margin-left: 15px;
                    flex-shrink: 0;
                    content: "×";
                    font-size: 16px;
                    color: @red;
                    width: 24px;
                    height: 24px;
                    line-height: 24px;
                    border: 1px #ccc solid;
                    border-radius: 50%;
                    text-align: center;
                    cursor: pointer;
                    .trs(all);
                }
                &:hover::before {
                    background: @red;
                    border-color: @red;
                    color: #fff;
                }
            }
        }
    }
    .example {
        font-size: 14px;
        margin-top: 10px;
    }
    .services-form {
        .button {
            border: 0;
        }
        .flex {
            gap: 16px;
            @media @sm {
                flex-direction: column;
            }
        }
    }
    .dropdown-menu {
        max-height: calc(42px * 7);
        overflow-y: auto;
    }
}
</style>
